import axios from 'axios';
import Cookie from 'js-cookie';

// 重试逻辑函数
const retryRequest = async (error, retries = 3) => {
    if (retries > 0 && (!error.response || error.response.status >= 500)) {
        // 如果是服务器错误且重试次数大于0，等待1秒后重试
        await new Promise(resolve => setTimeout(resolve, 1000));
        return httpNews.request(error.config).then(response => response).catch(error => retryRequest(error, retries - 1));
    }
    return Promise.reject(error);
};

const httpNews = axios.create({
    // 通用请求地址前缀
    baseURL: 'https://www.askition.com:9201/',
    timeout: 30000,
});

// 添加请求拦截器
httpNews.interceptors.request.use(function (config) {
    // 在发送请求之前添加header token
    const token = Cookie.get('token');
    const headers = config.headers;
    if (token && !headers.token) {
        headers.token = token;
    }
    return config;
}, function (error) {
    // 对请求错误做些什么
    return Promise.reject(error);
});

// 添加响应拦截器
httpNews.interceptors.response.use(function (response) {
    // 对响应数据做点什么
    return response;
}, function (error) {
    // 对响应错误做点什么
    return retryRequest(error);
});

export default httpNews;
